<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login-pic.jpg" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg" @keyup.enter="login">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Willkommen zurück, bitte logge dich ein.</p>
                </div>

                <div>
                  <vs-input
                    v-validate="'required|email'"
                    name="field1"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="email"
                    class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                  <vs-input
                    v-validate="'required'"
                    type="password"
                    name="field2"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Passwort"
                    v-model="password"
                    class="w-full mt-6"/>
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-between my-5">
                    <router-link to="">Forgot Password?</router-link>
                  </div>

                  <vs-button to="/register" type="border">Register</vs-button>
                  <vs-button :disabled="!validateForm" @click="login" class="float-right">Login</vs-button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import ApiService from "../../api";

  export default {
    data() {
      return {
        email: "",
        password: "",
        checkbox_remember_me: false,
      }
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.email != '' && this.password != '';
      },
    },
    methods: {
      login() {
        let me = this;

        if (!this.validateForm) return;

        me.$vs.loading();

        ApiService.post('auth', {email: this.email, password: this.password}).then((response) => {
          if (response.status !== 200) {
            return;
          }
          localStorage.setItem("apiKey_" + response.data.workspace.id, response.data.api_key);
          localStorage.setItem("current_workspace", response.data.workspace.id);

          ApiService.setHeader( localStorage.getItem("apiKey_" + response.data.workspace.id));

          ApiService.get('users/'+response.data.api_key).then((response) => {
            me.$vs.loading.close();
            me.$store.commit('SET_USER', response.data.result);
            ApiService.get('workspaces/' + response.data.result.workspace.id).then((response) => {
              me.$store.commit('SET_WORKSPACE', response.data.result);
              me.$router.push('/');
            }).catch(response => {
              me.$vs.loading.close();
              localStorage.removeItem("apiKey_" + response.data.workspace.id);
              localStorage.removeItem("current_workspace");
            })
          }).catch(error => {
              me.$vs.loading.close();
            localStorage.removeItem("apiKey_" + response.data.workspace_id);
            localStorage.removeItem("current_workspace");
          });

        }).catch((error) => {
          me.$vs.loading.close();
          me.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        });

      },
    }
  }
</script>

<style lang="scss">
</style>
